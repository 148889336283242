import axiosInstance from "utils/axiosInterceptor";

export function list(data: any) {
  return axiosInstance({
      url: "platformReview/list?" + data,
    method: "get",
  });
}

export function getById(id: string) {
  return axiosInstance({
    url: "waitUsersDetails?id=" + id,
    method: "get",
  });
}

export const apiMethod = {
  list,
  getById
};
