import axiosInstance from "utils/axiosInterceptor";

export function list(data: any) {
    return axiosInstance({
        url: "waitListUsers?" + data,
        method: "get",
    });
}

export function getById(id: string) {
    return axiosInstance({
        url: "platformReview/NewListById?id=" + id,
        method: "get",
    });
}

export const apiMethod = {
    list,
    getById
};
